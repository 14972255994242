import React, { FC } from "react";
import dynamic from "next/dynamic";
import { Flip, ToastContainerProps } from "react-toastify";
import { createGlobalStyle } from "styled-components";

const ToastContainer = dynamic<ToastContainerProps>(() => import("react-toastify").then(m => m.ToastContainer))

export type Props = Record<string, never>
const StyledToastContainer: FC<Props> = () => {
  return (
    <>
      <ToastStyle />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss={true}
        transition={Flip}
        draggable={true}
        pauseOnHover={true}
      />
    </>
  )
}
export default StyledToastContainer;

const ToastStyle = createGlobalStyle`
  .Toastify__toast--info {
    background-color: #F5F5F5;
    color: #000;
    border: solid 4px #C0C0C0;
    border-radius: 0;
  }

  .Toastify__toast--warning {
    background-color: #FFE501;
    color: #000;
  }

  .Toastify__toast-body {
    white-space: pre-line;
  }
`
